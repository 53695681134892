@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@100;200;300;500;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none !important;
}

/* navbar background */
.navbar_nature {
  background-color: #fff !important;
  color: red !important;
}

.navbar_active {
  background-color: #252748 !important;
}

/* end of navbar background */

/* navbar color */
.navbar_nature_color {
  color: #777E90 !important
}

.navbar_nature_color:hover {
  color: #252748 !important
}

/* end of navbar color */

/* logo not active */
.navbar_nature_logo_color {
  color: #252748 !important
}

.navbar_nature_logo_color:hover {
  color: #0f1029 !important
}

/* end of logo not active */

/* logo active */
.navbar_active_logo_color {
  color: #fff !important
}

.navbar_active_logo_color:hover {
  color: #abaee1 !important
}

/* end of logo active */


/* navbar button  */
.navbar_head_button {
  background-color: #252748 !important;
  color: #fff;
}

.navbar_head_button:hover {
  background-color: #d4d5e4 !important;
  color: #252748;
}

.navbar_head_active_button {
  background-color: #d4d5e4 !important;
  color: #252748;
}

.navbar_head_active_button:hover {
  background-color: #9194d0 !important;
  color: #fff;
}

/* end of navbar button  */

.navbar_active_color {
  color: #fff !important;
}

.navbar_active_color:hover {
  color: #c1c4f1 !important;
}

.red_alert {
  color: red;
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 14px;
}

.navbar_profile_pics {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  /* margin-right: 10px; */
}

.sidebar_profile_pics {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: 20px;
  border: 3px solid #252748;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

}

.sidebar_profile_pics:hover {
  transition: all 0.2s ease-in-out;
}

.cart_number {
  font-size: 10px;
  background-color: #252748;
  color: #fff;
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 3px 5px;
  border-radius: 10px;
}

.cart_number_active {
  font-size: 10px;
  background-color: #fff;
  color: #252748;
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 3px 5px;
  border-radius: 10px;
}

.order_page_table {
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 90%;
  width: 90%;
}

.ambassador_navbar_show {
  opacity: 1 !important;
  visibility: visible !important;
  right: 1.5% !important;
}

.m_b_50 {
  margin-bottom: 50px !important;
}

.m_b_40 {
  margin-bottom: 40px !important;
}

.m_b_30 {
  margin-bottom: 30px !important;
}

.m_b_20 {
  margin-bottom: 20px !important;
}

.m_b_15 {
  margin-bottom: 15px !important;
}

.m_b_10 {
  margin-bottom: 10px !important;
}

.m_b_5 {
  margin-bottom: 5px !important;
}

.m_l_20 {
  margin-left: 20px !important;
}

.m_l_15 {
  margin-left: 15px !important;
}

.m_l_10 {
  margin-left: 10px !important;
}

.m_l_5 {
  margin-left: 5px !important;
}

.m_r_20 {
  margin-right: 20px !important;
}

.m_r_15 {
  margin-right: 15px !important;
}

.m_r_10 {
  margin-right: 10px !important;
}

.m_r_5 {
  margin-right: 5px !important;
}

.m_5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.m_50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.m_t_50 {
  margin-top: 50px !important;
}

.m_t_30 {
  margin-top: 30px !important;
}

.m_t_20 {
  margin-top: 20px !important;
}

.m_t_10 {
  margin-top: 10px !important;
}

.m_t_5 {
  margin-top: 5px !important;
}

.w_100 {
  width: 100% !important;
}

.w_90 {
  width: 90% !important;
}

.w_85 {
  width: 85% !important;
}

.w_80 {
  width: 80% !important;
}

.w_70 {
  width: 70% !important;
}

.w_60 {
  width: 60% !important;
}

.w_50 {
  width: 50% !important;
}

.w_40 {
  width: 40% !important;
}

.w_30 {
  width: 30% !important;
}

.w_20 {
  width: 20% !important;
}

.w_10 {
  width: 10% !important;
}

.w_5 {
  width: 5% !important;
}

.h_100 {
  height: 100% !important;
}

.h_90 {
  height: 90% !important;
}

.h_80 {
  height: 80% !important;
}

.h_70 {
  height: 70% !important;
}

.h_60 {
  height: 60% !important;
}

.h_50 {
  height: 50% !important;
}

.h_5 {
  height: 5% !important;
}

.back_to_top_app_css {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.703);
  color: #fff;
  text-align: center;
  font-size: 24px;
}

.back_to_top_app_css:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.close_modal_button {
  cursor: pointer;
  position: fixed;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 5px;
  z-index: 10;
  background: #000;
  color: #fff;
  border-radius: 50%;
}

.app_row {
  display: flex;
  flex-direction: row;
}

.x_extra_large_title {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 33px;
}

.extra_large_title {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 2.0rem;
  line-height: 33px;
}

.large_title {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 29px;
}

.medium_title {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 26px;
}

.small_title {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 24px;
}

/* slider */
.budget_slider.ant-slider-disabled .ant-slider-track {
  background-color: rgb(255, 209, 102) !important;
}

.budget_slider.ant-slider-disabled .ant-slider-rail {
  background-color: rgb(120, 127, 142) !important;
}

.explore_details_image_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;
}

.explore_details_image {
  width: 98%;
  height: 100%;
}

.custom_button {
  min-width: 100px;
  padding: 0.5rem;
  border-radius: 20px;
  background: #2f3b59;
  color: #fff;
  cursor: pointer;
  margin: 0.5rem;
  transition: all 0.2s ease-in-out;
  outline: none;
  border: none;
}

.custom_button:hover {
  background: #1e2439;
  color: #fff;
  transition: all 0.2s ease-in-out;
}


.preferences_not_clicked {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 20px;
  background: #fff;
  color: #353945;
  cursor: pointer;
  margin: 0.5rem;
  border: 2px solid #ccc;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
}

.preferences_not_clicked:hover {
  background: #353945;
  color: #fff;
  transition: all 0.2s ease-in-out;
}


.preferences_clicked {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 20px;
  background: #353945;
  color: #fff;
  cursor: pointer;
  margin: 0.5rem;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
}

.preferences_clicked:hover {
  background: #1b1d22;
  color: fff;
  transition: all 0.2s ease-in-out;
}

.pagination_row {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* START INPUT */

.custom_input {
  display: block;
  margin-top: 7px;
  margin-bottom: 20px;
  padding: 0.6rem;
  font-size: 1rem;
  border-radius: 10px;
  outline: none;
  border: 2px solid #ccc;
  width: 500px;
  color: #000;
  transition: all 0.3s ease-in-out;
}

.custom_input:focus {
  border: 2px solid #252748;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 700px) {
  .custom_input {
    width: 400px;
  }
}

@media screen and (max-width: 550px) {
  .custom_input {
    width: 300px;
  }
}

@media screen and (max-width: 345px) {
  .custom_input {
    width: 250px;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 300px) {
  .custom_input {
    width: 200px;
    font-size: 0.7rem;
  }
}

/* END START INPUT */


@media screen and (max-width: 500px) {
  .close_modal_button {
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
  }
}