.footer {
    max-width: 97%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #252748;
    padding: 20px;
    padding-bottom: 50px;
    margin: auto;
}

.footer_menu {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-wrap: wrap;
}

.footer_item {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 16px;
    padding: 10px;
    flex-wrap: wrap;
}

.footer_item_menu {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(33.33% - 1em);
    margin-bottom: 20px;
}

.footer_item_menu_name {
    margin-bottom: 10px;
}

.footer_item_link {
    text-decoration: none;
    color: #fff;
}

.brand_name {
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    -webkit-text-decoration: none;
    text-decoration: none;
}

.line {
    width: 90%;
    height: 1px;
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
}

.footer_all_right {
    margin-top: 10px;
    font-size: 16px;
    color: #fff;
    margin-right: 24px;
    letter-spacing: 1px;
}


.footer_newsletter_input {
    display: block;
    margin-top: 15px;
    margin-bottom: 20px;
    padding: 0.6rem;
    font-size: 1rem;
    border-radius: 10px;
    outline: none;
    border: 2px solid #ccc;
    width: 300px;
    color: #000;
    transition: all 0.3s ease-in-out;
}

.footer_newsletter_input:focus {
    border: 2px solid rgb(170, 5, 5);
    transition: all 0.3s ease-in-out;
}

.button {
    display: block;
    margin-top: 7px;
    margin-bottom: 25px;
    padding: 0.8rem;
    font-size: 1rem;
    border-radius: 40px;
    outline: none;
    /* border: 2px solid #ccc; */
    color: #FCFCFD;
    transition: all 0.3s ease-in-out;
    width: 170px;
    height: 48px;
    background: #7386d3;
    border-radius: 90px;
    border: none;
    outline: none;
    cursor: pointer;
}

.button:hover {
    background: #2b44a8;
    color: #FCFCFD;
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 800px) {
    .footer_item_menu {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: calc(50% - 1em);
    }
}

@media screen and (max-width: 500px) {
    .footer_item_menu {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: calc(100% - 1em);
    }
}

@media screen and (max-width: 400px) {
    .footer_newsletter_input {
        width: 250px;
        font-size: 1rem;
    }
}

@media screen and (max-width: 350px) {
    .footer_newsletter_input {
        width: 200px;
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 290px) {
    .footer_newsletter_input {
        width: 180px;
        font-size: 0.7rem;
    }
}